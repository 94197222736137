const RecentlyPlayedGames = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path
            d="M4.54408 4.56639C6.19536 2.90943 8.47724 1.88571 11 1.88571C16.0337 1.88571 20.1143 5.96632 20.1143 11C20.1143 16.0337 16.0337 20.1143 11 20.1143C5.96632 20.1143 1.88571 16.0337 1.88571 11C1.88571 10.4793 1.46358 10.0571 0.942857 10.0571C0.422132 10.0571 0 10.4793 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C7.95584 0 5.19901 1.23781 3.20839 3.23528C3.1764 3.26739 3.1472 3.30122 3.12079 3.33647L1.53985 1.75553C1.36738 1.58307 1.11073 1.52594 0.881397 1.60897C0.652068 1.692 0.49147 1.9002 0.469389 2.14309L0.0249215 7.03223C0.00803229 7.21801 0.0745356 7.4017 0.206444 7.5336C0.338353 7.66551 0.522039 7.73202 0.70782 7.71513L5.59696 7.27066C5.83985 7.24858 6.04805 7.08798 6.13108 6.85865C6.21411 6.62932 6.15698 6.37266 5.98452 6.2002L4.4406 4.65629C4.4767 4.62925 4.5113 4.59928 4.54408 4.56639Z"
            fill="white"
            fillOpacity="0.5"
        />
        <path
            d="M11.9429 4.71429C11.9429 4.19356 11.5207 3.77143 11 3.77143C10.4793 3.77143 10.0571 4.19356 10.0571 4.71429V11C10.0571 11.3251 10.2246 11.6272 10.5003 11.7995L14.2717 14.1567C14.7133 14.4327 15.295 14.2984 15.571 13.8569C15.847 13.4153 15.7127 12.8336 15.2711 12.5576L11.9429 10.4774V4.71429Z"
            fill="white"
            fillOpacity="0.5"
        />
    </svg>
);

export default RecentlyPlayedGames;
