import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dataLayerPush } from '@tlf-e/brand-utils';
import { initRegisterUser, setIsInteracted } from '../../../store/reducers/register';
import RenderContentOrError from './fragments/RenderContentOrError';

const RegisterNew = () => {
    const { isInteracted } = useSelector((state) => state.register);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(initRegisterUser());
            dispatch(setIsInteracted(false));
        };
    }, []);

    useEffect(() => {
        isInteracted && dataLayerPush({ event: 'signup_start' });
    }, [isInteracted]);

    return (
        <div className="form-container">
            <RenderContentOrError />
        </div>
    );
};

export default RegisterNew;
