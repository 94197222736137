import { globalWorkers } from '../../services/http';
import { localeCountryCapitalized } from '../../utils/locationConfig';

const { getContentBlockByTag } = globalWorkers;

const GET_LOBBY_HELP_CENTER_ERROR = 'GET_LOBBY_HELP_CENTER_ERROR';
const GET_LOBBY_HELP_CENTER_SUCCESS = 'GET_LOBBY_HELP_CENTER_SUCCESS';

export const initialState = {
    lobby: {
        isSuccess: false,
        isLoaded: false,
        isError: false,
        data: []
    }
};

const helpCenter = (state = initialState, action = {}) => {
    switch (action.type) {
    case GET_LOBBY_HELP_CENTER_SUCCESS:
        return {
            ...state,
            lobby: {
                ...state.lobby,
                data: action.payload,
                isSuccess: true,
                isLoaded: true,
                isError: false
            }
        };
    case GET_LOBBY_HELP_CENTER_ERROR:
        return {
            ...state,
            lobby: {
                ...state.lobby,
                data: [],
                isSuccess: false,
                isLoaded: true,
                isError: true
            }
        };
    default:
        return state;
    }
};

export default helpCenter;

export const getHelpCenter = (type, lang) => {
    const success = (payload) => ({
        type: GET_LOBBY_HELP_CENTER_SUCCESS,
        payload
    });

    const fail = () => ({
        type: GET_LOBBY_HELP_CENTER_ERROR
    });

    return (dispatch) => {
        getContentBlockByTag(localeCountryCapitalized(lang), type)
            .then((res) => {
                const {
                    data: { data }
                } = res;
                dispatch(success(data));
            })
            .catch(() => {
                dispatch(fail());
            });
    };
};
