import router from 'next/router';
import omit from 'lodash/omit';
import {
    CMS_SLUG_ANTI_MONEY_LAUNDERING,
    CMS_SLUG_CONTACT,
    CMS_SLUG_TERMS,
    CMS_SLUG_DISPUTE_RESOLUTION,
    CMS_SLUG_KYC,
    CMS_SLUG_FAIRNESS_RNG_TESTING_METHODS,
    CMS_SLUG_PAY_OUTS_BONUSES,
    CMS_SLUG_PRIVACY,
    CMS_SLUG_RESPONSIBLE_GAMING,
    CMS_SLUG_SELF_EXCLUSION,
    GAME_CATEGORY_SLOTS,
    GAME_CATEGORY_TABLE,
    GAME_CATEGORY_OTHER,
    GAME_CATEGORY_LIVE,
    GAME_ROUTER_PATHNAME
} from '../variables';
import routes from './routes.json';

export const categorySlugRoutes = (paths) => ({
    [GAME_CATEGORY_SLOTS]: `/${paths[routes.slot_machines]?.split('/').slice(2).join('/')}`,
    [GAME_CATEGORY_TABLE]: `/${paths[routes.table_games]?.split('/').slice(2).join('/')}`,
    [GAME_CATEGORY_OTHER]: `/${paths[routes.other_games]?.split('/').slice(2).join('/')}`,
    [GAME_CATEGORY_LIVE]: paths[routes.live_casino]
});

export const getGamePath = (game, gamesData, locale) => {
    const { softwares, categories } = gamesData;
    const software = softwares.find((software) => software.id === game?.softwareId);
    const category = categories.find((category) => category.id === game?.categoryId);
    const paths = getLocalizedPath(locale);
    const categoryPath = categorySlugRoutes(paths)[category?.name];
    const gamePath = `${categoryPath}${software?.slug}/${game?.slug}-${game?.id}`;
    return game?.tableHash ? `${gamePath}#table=${game?.tableHash}` : gamePath;
};

export const getProviderPath = (provider, locale) => {
    const paths = getLocalizedPath(locale);
    return `${paths[routes.games]}?providers=${provider.slug}`;
};

export const cmsNavLinks = {
    [CMS_SLUG_CONTACT]: routes.contact,
    [CMS_SLUG_RESPONSIBLE_GAMING]: routes.responsible_gaming,
    [CMS_SLUG_TERMS]: routes.terms,
    [CMS_SLUG_PRIVACY]: routes.privacy_policy,
    [CMS_SLUG_KYC]: routes.kyc,
    [CMS_SLUG_SELF_EXCLUSION]: routes.self_exclusion,
    [CMS_SLUG_DISPUTE_RESOLUTION]: routes.dispute_resolution,
    [CMS_SLUG_ANTI_MONEY_LAUNDERING]: routes.anti_money_laundering,
    [CMS_SLUG_FAIRNESS_RNG_TESTING_METHODS]: routes.fairness_rng_testing_methods,
    [CMS_SLUG_PAY_OUTS_BONUSES]: routes.pay_outs_bonuses
};

export const getLocalizedPath = (locale) => {
    const paths = Object.keys(routes).reduce((a, b) => ({ ...a, [routes[b]]: routes[b] }), {});
    const localized = Object.keys(localizedPathnames).reduce((a, b) => ({ ...a, [b]: localizedPathnames[b][locale] }), {});
    return {
        ...paths,
        ...localized
    };
};

export const localizedPathnames = {
    [routes.slot_machines]: {
        en: routes.slot_machines,
        'ja-jp': routes.slot_machines
    },
    [routes.other_games]: {
        en: routes.other_games,
        'ja-jp': routes.other_games
    },
    [routes.register]: {
        en: routes.register,
        'ja-jp': routes.register
    },
    [routes.responsible_gaming]: {
        en: routes.responsible_gaming,
        'ja-jp': routes.responsible_gaming
    },
    [routes.terms]: {
        en: routes.terms,
        'ja-jp': routes.terms
    },
    [routes.privacy_policy]: {
        en: routes.privacy_policy,
        'ja-jp': routes.privacy_policy
    },
    [routes.self_exclusion]: {
        en: routes.self_exclusion,
        'ja-jp': routes.self_exclusion
    },
    [routes.dispute_resolution]: {
        en: routes.dispute_resolution,
        'ja-jp': routes.dispute_resolution
    },
    [routes.anti_money_laundering]: {
        en: routes.anti_money_laundering,
        'ja-jp': routes.anti_money_laundering
    },
    [routes.fairness_rng_testing_methods]: {
        en: routes.fairness_rng_testing_methods,
        'ja-jp': routes.fairness_rng_testing_methods
    },
    [routes.pay_outs_bonuses]: {
        en: routes.pay_outs_bonuses,
        'ja-jp': routes.pay_outs_bonuses
    },
    [routes.why_us]: {
        en: routes.why_us,
        'ja-jp': routes.why_us
    },
    [routes.live_casino]: {
        en: routes.live_casino,
        'ja-jp': routes.live_casino
    },
    [routes.live_casino_games]: {
        en: routes.live_casino_games,
        'ja-jp': routes.live_casino_games
    },
    [routes.games_lobby]: {
        en: routes.games_lobby,
        'ja-jp': routes.games_lobby
    },
    [routes.recently_played]: {
        en: routes.recently_played,
        'ja-jp': routes.recently_played
    },
    [routes.favorite_games]: {
        en: routes.favorite_games,
        'ja-jp': routes.favorite_games
    },
    [routes.bonuses]: {
        en: routes.bonuses,
        'ja-jp': routes.bonuses
    },
    [routes.promotions]: {
        en: routes.promotions,
        'ja-jp': routes.promotions
    },
    [routes.account_profile]: {
        en: routes.account_profile,
        'ja-jp': routes.account_profile
    },
    [routes.contact]: {
        en: routes.contact,
        'ja-jp': routes.contact
    },
    [routes.kyc]: {
        en: routes.kyc,
        'ja-jp': routes.kyc
    },
    [routes.login]: {
        en: routes.login,
        'ja-jp': routes.login
    },
    [routes.games]: {
        en: routes.games,
        'ja-jp': routes.games
    },
    [routes.online_roulette]: {
        en: routes.online_roulette,
        'ja-jp': routes.online_roulette
    },
    [routes.free_casino_games]: {
        en: routes.free_casino_games,
        'ja-jp': routes.free_casino_games
    },
    [routes.free_slot_machines]: {
        en: routes.free_slot_machines,
        'ja-jp': routes.free_slot_machines
    },
    [routes.casino_guide]: {
        en: routes.casino_guide,
        'ja-jp': routes.casino_guide
    },
    [routes.wallet]: {
        en: routes.wallet,
        'ja-jp': routes.wallet
    },
    [routes.table_games]: {
        en: routes.table_games,
        'ja-jp': routes.table_games
    }
};

export const localizePath = (router) => {
    const isGame = GAME_ROUTER_PATHNAME === router.pathname;
    const { categoryName } = router.query;
    const localizedPage = localizedPathnames[isGame ? `/${categoryName}/` : `${router.pathname}/`];
    let path = router.asPath;
    // replace with correct translation regarding the current locale
    if (localizedPage && !path.includes(localizedPage[router.locale])) {
        const lang = Object.keys(localizedPage).filter((key) => path.includes(localizedPage[key]))[0];
        path = path.replace(localizedPage[lang], localizedPage[router.locale]);
        router.replace(router.pathname, path);
    }
};

export const handleCloseModal = (paramsToBeRemoved = [], shouldReopen = false) => {
    const hash = window.location.hash;
    const [asPathRoot, asPathQuery = ''] = router.asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    !shouldReopen && params.delete('modal');

    for (let i = 0; i < paramsToBeRemoved.length; i++) {
        params.delete(paramsToBeRemoved[i]);
    }

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    if (hash) {
        updatedParams = `${updatedParams}${hash}`;
    }

    router.push(
        {
            pathname: router.pathname,
            query: {
                ...omit(router.query, shouldReopen ? paramsToBeRemoved : ['modal', ...paramsToBeRemoved])
            }
        },
        `${asPathRoot}${updatedParams}`
    );
};

export const handleCloseDepositModal = () => {
    handleCloseModal(['transactionId']);
};

export const handleCloseMenu = () => {
    const hash = window.location.hash;
    const [asPathRoot, asPathQuery = ''] = router.asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    params.delete('menu');

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    if (hash) {
        updatedParams = `${updatedParams}${hash}`;
    }

    router.push(
        {
            pathname: router.pathname,
            query: {
                ...omit(router.query, ['menu'])
            }
        },
        `${asPathRoot}${updatedParams}`
    );
};

export const updateParamValue = (key, value, router) => {
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.split('?');
    const params = new URLSearchParams(asPathQuery);
    let query = { ...routerQuery };
    if (value > 1) {
        params.set(key, value);
        query = {
            ...query,
            [key]: value
        };
    } else {
        params.delete(key);
        query = { ...omit(query, [key]) };
    }

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    const href = {
        pathname,
        query
    };
    const as = `${asPathRoot}${updatedParams}`;

    return {
        href,
        as
    };
};

export const getModalUrl = (modalName, router) => {
    const hash = window.location.hash;
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    params.set('modal', modalName);
    let query = {
        ...routerQuery,
        modal: modalName
    };

    const href = {
        pathname,
        query
    };

    let as = `${asPathRoot}?${params.toString()}`;
    if (hash) {
        as = `${as}${hash}`;
    }

    return {
        href,
        as
    };
};

export const getMenuUrl = (menuName, router, hideParam) => {
    const hash = window.location.hash;
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.replace(hash, '').split('?');
    const params = new URLSearchParams(asPathQuery);
    if (!hideParam) {
        params.set('menu', menuName);
    }
    let query = {
        ...routerQuery,
        menu: menuName
    };

    const href = {
        pathname,
        query
    };
    let as = asPathRoot;
    const stringifiedParams = params.toString();

    if (stringifiedParams) {
        as = `${as}?${stringifiedParams}`;
    }

    if (hash) {
        as = `${as}${hash}`;
    }

    return {
        href,
        as
    };
};

export const getGamesPath = (updatedQuery, router) => {
    const { pathname, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.split('?');
    const params = new URLSearchParams(asPathQuery);

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    const href = {
        pathname,
        query: updatedQuery
    };
    const as = `${asPathRoot}${updatedParams}`;

    return {
        href,
        as
    };
};

export const getGamesListPath = (key, value, router) => {
    const { pathname, query: routerQuery, asPath } = router;
    const [asPathRoot, asPathQuery = ''] = asPath.split('?');
    const params = new URLSearchParams(asPathQuery);
    let query = { ...routerQuery };
    if (value) {
        params.set(key, value);
        query = {
            ...query,
            [key]: value
        };
    } else {
        params.delete(key);
        query = { ...omit(query, [key]) };
    }

    let updatedParams = '';
    if ([...params].length) {
        updatedParams = `?${params.toString()}`;
    }

    const href = {
        pathname,
        query
    };
    const as = `${asPathRoot}${updatedParams}`;

    return {
        href,
        as
    };
};

export const isActiveCasinoMenuItem = (pathname) =>
    [
        routes.games_lobby,
        routes.games,
        routes.recently_played,
        routes.favorite_games,
        routes.slot_machines,
        routes.table_games,
        routes.other_games,
        `${routes.slot_machines}[subcategory]/`,
        `${routes.table_games}[subcategory]/`,
        `${routes.other_games}[subcategory]/`
    ].includes(pathname);

export const isActiveLiveCasinoMenuItem = (pathname) =>
    [routes.live_casino, routes.live_casino_games, routes.live_casino_games_category].includes(pathname);

export const setPaginationPage = (page) => {
    const { href, as } = updateParamValue('page', page, router);
    return router.replace(href, as);
};

export const shortenedLocale = (locale = '') => locale.split('-')[0] ?? locale.slice(0, 2);

export const localeCountryCapitalized = (locale = '') => {
    const parts = locale.split('-');
    if (parts.length > 1) {
        return `${parts[0]}-${parts[1].toUpperCase()}`;
    } else {
        return locale;
    }
};

export const includeRouterContext = (method, ...args) => {
    return method.call(router, ...args);
};
