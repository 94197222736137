import { registerWorkers } from '../../services/http';
import { getUserInformation } from './user';
import { BAD_REQUEST, COOKIE_NAME_TOKEN, MENU_REGISTER } from '../../variables';
import router from 'next/router';
import routes from '../../utils/routes.json';
import { dataLayerPush, handleRegistrationError, getSeonBase64Session } from '@tlf-e/brand-utils';
import { setDomainCookie } from '../../utils/cookieConfig';

const { csrfRegRequest, registrationRequest, verifyEmailRequest, verifyPhoneNumberRequest, } = registerWorkers;
const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
const REGISTER_USER = 'REGISTER_USER';
const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
const REGISTER_USER_INIT = 'REGISTER_USER_INIT';
const CLEANUP_SUBMIT_REGISTRATION = 'CLEANUP_SUBMIT_REGISTRATION';
const COMBINE_REGISTRATION_FORM = 'COMBINE_REGISTRATION_FORM';
const VERIFY_EMAIL_FIELD = 'VERIFY_EMAIL_FIELD';
const VERIFY_EMAIL_FIELD_PROCESSED = 'VERIFY_EMAIL_FIELD_PROCESSED';
const VERIFY_PHONE_NUMBER_FIELD = 'VERIFY_PHONE_NUMBER_FIELD';
const VERIFY_PHONE_NUMBER_FIELD_PROCESSED = 'VERIFY_PHONE_NUMBER_FIELD_PROCESSED';
const SET_IS_SUSPENDED = 'SET_IS_SUSPENDED';
const CLOSE_ERROR_REGISTER_MODAL = 'CLOSE_ERROR_REGISTER_MODAL';
const SET_REGISTER_FROM_INTERACTED = 'SET_REGISTER_FROM_INTERACTED';
const SET_IS_WELCOME_MODAL_OPENED = 'SET_IS_WELCOME_MODAL_OPENED';
const INIT_FORM = 'INIT_FORM';
const SET_GENERAL_REGISTRATION_ERROR = 'SET_GENERAL_REGISTRATION_ERROR';

const initialState = {
    activeStep: 1,
    isInteracted: false,
    submitRegistration: {
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: ''
    },
    form: {
        country: '',
        email: '',
        password: '',
        username: '',
        countryPhoneCodePrefix: '',
        phone: '',
        firstname: '',
        lastname: '',
        birthDate: '',
        currency: '',
        gender: '',
        address: '',
        city: '',
        zipCode: '',
        marketingAllowed: false,
        affiliateId: null,
        tracker: null,
        language: '',
        termsConditions: false
    },
    emailFieldVerification: {
        isLoading: false,
        isSuccess: false,
        error: ''
    },
    phoneNumberFieldVerification: {
        isLoading: false,
        isSuccess: false,
        error: ''
    },
    isRegistrationAllowed: true,
    isRegistrationAllowedLoaded: false,
    isSuspended: false,
    totalSteps: 3,
    isWelcomeModalOpened: false,
    generalRegistrationError: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
    case SET_ACTIVE_STEP:
        return {
            ...state,
            activeStep: action.payload
        };
    case SET_REGISTER_FROM_INTERACTED:
        return {
            ...state,
            isInteracted: action.payload
        };
    case COMBINE_REGISTRATION_FORM:
        return {
            ...state,
            form: {
                ...state.form,
                ...action.payload
            }
        };
    case REGISTER_USER:
        return {
            ...state,
            submitRegistration: {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        };
    case REGISTER_USER_SUCCESS:
        return {
            ...state,
            form: action.form,
            submitRegistration: {
                isLoading: false,
                isSuccess: true,
                isError: false
            }
        };
    case REGISTER_USER_FAILED:
        return {
            ...state,
            submitRegistration: {
                isLoading: false,
                isSuccess: false,
                isError: true,
                error: action.payload
            }
        };
    case REGISTER_USER_INIT:
        return {
            ...state,
            activeStep: 1,
            submitRegistration: {
                isLoading: false,
                isSuccess: false,
                isError: false
            }
        };
    case CLEANUP_SUBMIT_REGISTRATION:
        return {
            ...state,
            submitRegistration: {
                isLoading: false,
                isSuccess: false,
                isError: false,
                error: ''
            }
        };
    case VERIFY_PHONE_NUMBER_FIELD:
        return {
            ...state,
            phoneNumberFieldVerification: {
                isLoading: true,
                isSuccess: false,
                error: ''
            }
        };
    case VERIFY_PHONE_NUMBER_FIELD_PROCESSED:
        return {
            ...state,
            phoneNumberFieldVerification: action.payload
        };
    case VERIFY_EMAIL_FIELD:
        return {
            ...state,
            emailFieldVerification: {
                isLoading: true,
                isSuccess: false,
                error: ''
            }
        };
    case VERIFY_EMAIL_FIELD_PROCESSED:
        return {
            ...state,
            emailFieldVerification: action.payload
        };
    case SET_IS_SUSPENDED:
        return {
            ...state,
            isSuspended: action.payload
        };
    case CLOSE_ERROR_REGISTER_MODAL:
        return {
            ...state,
            submitRegistration: {
                ...state.submitRegistration,
                isError: false
            }
        };
    case SET_IS_WELCOME_MODAL_OPENED:
        return {
            ...state,
            isWelcomeModalOpened: action.payload
        };
    case INIT_FORM:
        return {
            ...state,
            form: {
                country: '',
                email: '',
                password: '',
                username: '',
                countryPhoneCodePrefix: '',
                phone: '',
                firstname: '',
                lastname: '',
                birthDate: '',
                currency: '',
                gender: '',
                address: '',
                city: '',
                zipCode: '',
                marketingAllowed: false,
                affiliateId: null,
                tracker: null,
                language: '',
                termsConditions: false
            }
        };
    case SET_GENERAL_REGISTRATION_ERROR:
        return {
            ...state,
            generalRegistrationError: action.payload
        };
    default:
        return state;
    }
};

export const setActiveStep = (payload) => ({
    type: SET_ACTIVE_STEP,
    payload
});

export const combineRegistrationForm = (payload) => ({
    type: COMBINE_REGISTRATION_FORM,
    payload
});

export const registerUser = (data) => {
    const { email, username, address, country, phone, countryPhoneCodePrefix, zipCode, city, password } = data;
    const phoneNumber = countryPhoneCodePrefix + phone;
    const loading = () => ({
        type: REGISTER_USER
    });
    const success = (payload, form) => ({
        type: REGISTER_USER_SUCCESS,
        payload,
        form
    });
    const fail = (payload) => ({
        type: REGISTER_USER_FAILED,
        payload
    });

    dataLayerPush({
        event: 'Signup_Submission',
        sub_email: email,
        sub_username: username,
        sub_address: address,
        sub_country: country,
        sub_Phone_number: phoneNumber,
        sub_zipCode: zipCode,
        sub_city: city
    });

    const csrfData = {
        email: email,
        password: password
    };

    const regRequestFunc = (session = {}) => {
        return (dispatch, getState) => {
            csrfRegRequest(csrfData)
                .then((token) =>
                    registrationRequest({ ...data, ...session, csrf: token.data.data.token })
                        .then(async (res) => {
                            await setDomainCookie(COOKIE_NAME_TOKEN, res.data.data.token);
                            dispatch(success(res.data, data));
                            dataLayerPush({ event: 'signup_success' });
                            dispatch(getUserInformation(res.data.data.token, data.language, 'firstLogin'));
                            router.push(routes.home).finally(() => {
                                dispatch(setIsWelcomeModalOpened(true));
                                dispatch(initRegisterUser());
                                dispatch(initForm());
                            });
                        })
                        .catch((err) => {
                            if (err.status === 401) {
                                router.push(routes.home);
                                return dispatch(setIsSuspended(true));
                            }

                            dataLayerPush({
                                event: 'signup_failure',
                                sub_address: address,
                                sub_country: country,
                                sub_Phone_number: phoneNumber,
                                sub_zipCode: zipCode,
                                sub_city: city
                            });

                            const tr = getState().global.data.translations;

                            if (err.status === 500) {
                                dispatch(fail(''));
                                return dispatch(setGeneralRegistrationError(true));
                            }

                            const error = handleRegistrationError(err, tr);
                            dispatch(fail(error));
                            window.scrollTo(0, 0);
                        })
                )
                .catch(() => dispatch(fail()));
        };
    };

    return (dispatch) => {
        dispatch(loading());
        getSeonBase64Session(MENU_REGISTER)
            .then(({ seonBase64Session }) => {
                dispatch(regRequestFunc({ seonSession: seonBase64Session }));
            })
            .catch(() => {
                dataLayerPush({ event: 'getSeonBase64Session_error', error: 'on_registration' });
                dispatch(regRequestFunc());
            });
    };
};

export const initRegisterUser = () => ({
    type: REGISTER_USER_INIT
});

export const cleanupSubmitRegistration = () => ({
    type: CLEANUP_SUBMIT_REGISTRATION
});

export const processEmailVerification = (payload) => ({
    type: VERIFY_EMAIL_FIELD_PROCESSED,
    payload
});

export const loadingEmailFieldVerification = () => ({
    type: VERIFY_EMAIL_FIELD
});

export const verifyEmailField = (data) => {
    const payload = (isSuccess, error) => ({
        isLoading: false,
        isSuccess,
        error,
        value: data.email
    });

    return (dispatch, getState) => {
        const tr = getState().global.data.translations;
        dispatch(loadingEmailFieldVerification());
        verifyEmailRequest(data)
            .then((res) => {
                const { valid, messages } = res.data.data;
                const error = valid ? '' : tr[`${messages[0] || BAD_REQUEST}`];
                dispatch(processEmailVerification(payload(valid, error)));
            })
            .catch(() => {
                const error = tr['register.form.email.error.invalid'];
                dispatch(processEmailVerification(payload(false, error)));
            });
    };
};

export const processPhoneNumberVerification = (payload) => ({
    type: VERIFY_PHONE_NUMBER_FIELD_PROCESSED,
    payload
});

export const verifyPhoneNumberField = (number, prefix, error) => {
    const loading = () => ({
        type: VERIFY_PHONE_NUMBER_FIELD
    });
    return (dispatch) => {
        const phoneNumber = `${prefix}${number}`;
        const data = {
            phoneNumber: phoneNumber,
            type: 'string'
        };
        dispatch(loading());
        verifyPhoneNumberRequest(data)
            .then((res) => {
                dispatch(
                    processPhoneNumberVerification({
                        isLoading: false,
                        isSuccess: res.data.data.valid,
                        error: res.data.data.valid ? '' : error,
                        value: phoneNumber
                    })
                );
            })
            .catch(() => {
                dispatch(
                    processPhoneNumberVerification({
                        isLoading: false,
                        isSuccess: false,
                        error: '',
                        value: phoneNumber
                    })
                );
            });
    };
};

export const setIsSuspended = (payload) => ({
    type: SET_IS_SUSPENDED,
    payload
});

export const closeErrorRegisterModal = () => ({
    type: CLOSE_ERROR_REGISTER_MODAL
});

export const setIsInteracted = (payload) => ({
    type: SET_REGISTER_FROM_INTERACTED,
    payload
});

export const setIsWelcomeModalOpened = (payload) => ({
    type: SET_IS_WELCOME_MODAL_OPENED,
    payload
});

export const initForm = () => ({
    type: INIT_FORM
});

export const setGeneralRegistrationError = (payload) => ({
    type: SET_GENERAL_REGISTRATION_ERROR,
    payload
});
