import RegistrationErrorView from './RegistrationErrorView';
import { useSelector } from 'react-redux';
import RegisterStep1New from '../RegisterStep1New';
import RegisterStep2New from '../steps/RegisterStep2New';
const forms = {
    1: <RegisterStep1New />,
    2: <RegisterStep2New />,
};

const RenderContentOrError = () => {
    const { generalRegistrationError } = useSelector((state) => state.register);
    const { loc } = useSelector((state) => state.global.clientIp);
    const { allowedCountries } = useSelector((state) => state.global.data);
    const tr = useSelector((state) => state.global.data.translations);
    const { activeStep } = useSelector((state) => state.register);

    if (generalRegistrationError)
        return (
            <RegistrationErrorView
                title={tr['registration.general.error.title']}
                details={tr['registration.general.error.details']}
            />
        );

    if(!allowedCountries.includes(loc)) {
        return (
            <RegistrationErrorView
                title={tr['not_logged.title']}
                details={tr['blocked_registration_attempted_wrong_geo_loc']}
                queries={tr['blocked_registration_queries']}
            />
        );
    }

    return forms[activeStep];
};

export default RenderContentOrError;
