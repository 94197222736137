import { useEffect } from 'react';

export const useCaptainUpWidget = (isOpen) => {

    useEffect(() => {
        if (isOpen) {
            const captainUpWidget = document.getElementById('_captain_up');

            if (captainUpWidget) {
                captainUpWidget.style.display = 'none';

                return () => {
                    captainUpWidget.style.display = 'block';
                };
            }

        }
    }, [isOpen]);

    return null;
};
